@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.effra {
    font-family: "effra", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.effra-italic-regular {
    font-family: "effra", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.effra-bold {
    font-family: "effra", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.effra-italic-bold {
    font-family: "effra", sans-serif;
    font-weight: 700;
    font-style: italic;
}

sup {
    top: -4px;
}

.text-balance {
    text-wrap: balance;
}